
import { defineComponent, ref, computed } from '@vue/composition-api';
import store, { useUserActions, useUserState } from '@/store';
import { ActionTypes } from '@/store/modules/tools/actions';

export default defineComponent({
  name: 'MSolveRealWorldProblems',
  components: {
    ACombobox: () => import('@/components/atoms/ACombobox.vue'),
    OnboardDialog: () => import('@/components/molecules/m-onboarding.vue')
  },
  props: {
    fetchProgram: {
      required: false,
      type: [Object, Function],
      default: () => {}
    },
    adkType: {
      required: false,
      type: String,
      default: ''
    },
    userType: {
      required: true,
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(_props: any, _ctx: any) {
    const { user }: any = useUserState(['user']);
    const { getPathways, getProblemScope, saveProgressOfSolveADK } = useUserActions([
      'getPathways',
      'getProblemScope',
      'saveProgressOfSolveADK'
    ]);

    const programDocument: any = computed({
      get: () => _props.value,
      set: newVal => {
        console.log('programDoc', newVal);
      }
    });

    const adkIndex = computed(() => {
      const indx = programDocument.value?.data.adks?.findIndex(adk => {
        if (adk) {
          return adk.name === 'Solve Real World Problems';
        }
        return false;
      });
      return indx;
    });

    // const fieldIndex = computed(() => {
    //   const Fdindx = programDocument.value?.data?.adks?.[adkIndex.value]?.fields?.findIndex(
    //     field => {
    //       if (field.name) {
    //         return field.name === 'm-solve-real-world-problems';
    //       }
    //       return false;
    //     }
    //   );
    //   return Fdindx;
    // });

    const openLogsDialog = ref(false);
    const previousLogs = ref(programDocument?.value?.data?.problemScope || []);

    const pathways = ref('Any');
    const pathwaysOptions = ref(['Any']);
    const styling = {
      class: 'module-setup__combobox',
      placeholder: 'Choose a pathway',
      label: 'Choose a pathway',
      outlined: true,
      rounded: true,
      'small-chips': true,
      'hide-details': true
    };
    const isLoading = ref(false);
    const isDisplayProblem = ref(false);
    const problemData = ref();
    const solution = ref();
    const isSavingSolution = ref(false);
    const timerInSeconds = programDocument?.value?.data?.adks?.[adkIndex.value]?.timeToSolveProblem
      ? Number(programDocument?.value?.data?.adks?.[adkIndex.value]?.timeToSolveProblem)
      : 60;
    const countdown = ref(timerInSeconds); // Initial value set to 60 seconds
    let interval;
    const isOnboarding = ref(false);
    const isSaved = ref(false);

    const saveSolution = async (event = null) => {
      clearInterval(interval);
      if (event !== null) isSavingSolution.value = true;
      const timeTaken = timerInSeconds - countdown.value;
      clearInterval(interval);
      const payload = {
        employer: problemData?.value?.employer,
        timeSpent: timeTaken === 1 ? `${timeTaken} second` : `${timeTaken} seconds`,
        problem: problemData?.value?.problem,
        solution: solution?.value,
        pathways: pathways?.value
      };
      if (localStorage.getItem('apollo-token')) {
        // console.log('programDocument?.value.data', programDocument?.value.data);
        // if (programDocument.value?.data?.adks?.[adkIndex.value]?.fields[fieldIndex.value]) {
        //   programDocument.value.data.adks[adkIndex.value].fields[fieldIndex.value].setup = {
        //     problemScope: payload
        //   };
        // }
        // programDocument.value.data.problemScope = payload;
        // console.log('ProblemScope', programDocument.value.data);
        // await saveProgressOfSolveADK(programDocument.value.data);
        const data = {
          operation_type: 'update',
          program_id:
            programDocument?.value.data?.program_id?.toString() ||
            programDocument?.value.data?._id?.toString(),
          user_id: user?.value?._id?.toString(),
          problem_scope: payload
        };
        const studentData = await saveProgressOfSolveADK(data);
        // console.log('studentData', studentData);
        if (studentData) {
          // store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          //   message: 'Congrats, your solution saved successfully.',
          //   type: 'success',
          //   isShowSnackbar: true
          // });
          countdown.value = timerInSeconds;
          problemData.value = null;
          solution.value = '';
          pathways.value = 'Any';
          isDisplayProblem.value = false;
          if (!localStorage.getItem('problemScope')) {
            previousLogs.value.push(payload);
            isSaved.value = true;
          }
          // programDocument.value.data = studentData;
        }
      } else {
        isOnboarding.value = true;
        localStorage.setItem('problemScope', JSON.stringify(payload));
        localStorage.setItem('setDemoProgramRoute', _ctx.root.$route.path);
      }
      if (event !== null) isSavingSolution.value = false;
    };

    const startCountdown = async () => {
      interval = setInterval(async () => {
        countdown.value -= 1; // Decrement the countdown by 1 every second
        if (countdown.value === 0) {
          // clearInterval(interval); // Stop the timer when it reaches 0
          await saveSolution();
        }
      }, 1000);
    };

    // Watch for changes in countdown and update the timer value
    const timer = computed(() => {
      const minutes = Math.floor((countdown.value % 3600) / 60);
      const seconds = countdown.value % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    });

    const getPathwaysOptions = async () => {
      const pathwaysResultValue: any = await getPathways();
      if (pathwaysResultValue) {
        if (localStorage.getItem('problemScope')) {
          isSaved.value = true;
          previousLogs.value.push(JSON.parse(`${localStorage.getItem('problemScope')}`));
          localStorage.removeItem('problemScope');
        }
        if (localStorage.getItem('setDemoProgramRoute')) {
          localStorage.removeItem('setDemoProgramRoute');
        }
        const pathwaysOptionValue = pathwaysResultValue?.pathways
          .filter(pathway => pathway?.type === 'pathways')
          .map(pathway => pathway?.name);
        pathwaysOptions.value = [...pathwaysOptions.value, ...pathwaysOptionValue];
        // console.log('pathwaysOptions', pathwaysOptions?.value);
      }
    };

    getPathwaysOptions();

    const generateProblemScope = async () => {
      isLoading.value = true;
      try {
        const problem = await getProblemScope({ pathways: [pathways?.value] });
        if (problem) {
          isDisplayProblem.value = true;
          problemData.value = problem;
          await startCountdown();
        } else {
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: 'Problem not found with the specified pathways.',
            type: 'error',
            isShowSnackbar: true
          });
        }
      } catch (error) {
        console.log('error', error);
      } finally {
        isLoading.value = false;
      }
    };

    const openWebsiteInNewWindow = () => {
      window.open(problemData?.value?.website, '_blank');
    };

    async function toggleDialog(val) {
      await _props.fetchProgram();
      isOnboarding.value = val;
      if (!localStorage.getItem('apollo-token') && countdown.value !== 0) {
        await startCountdown();
      }
      if (localStorage.getItem('apollo-token') && localStorage.getItem('problemScope')) {
        // console.log('programDoc', programDocument.value.data);
        // console.log(localStorage?.getItem('problemScope'));
        await saveSolution('FromBtnClick');
      } else if (!localStorage.getItem('apollo-token')) {
        localStorage.removeItem('problemScope');
      }
      localStorage.removeItem('setDemoProgramRoute');
    }

    return {
      pathways,
      pathwaysOptions,
      styling,
      isLoading,
      isDisplayProblem,
      problemData,
      solution,
      timer,
      isSavingSolution,
      isOnboarding,
      previousLogs,
      openLogsDialog,
      isSaved,
      generateProblemScope,
      openWebsiteInNewWindow,
      saveSolution,
      toggleDialog
    };
  }
});
